.App {
  color: red;
}

@media screen and (max-width: 420px) and (min-width: 280px) {
  .App p {
    font-size: 12px;
  }
}


@font-face {
  font-family: MarkPro;
  src: url(../public/fonts/MarkPro-Bold.otf);
}

@font-face {
  font-family: MavenBold;
  src: url(../public/fonts/MavenPro-Bold.ttf);
}


@font-face {
  font-family: MavenExtraBold;
  src: url(../public/fonts/MavenPro-ExtraBold.ttf);
}


@font-face {
  font-family: MavenMedium;
  src: url(../public/fonts/MavenPro-Medium.ttf);
}

@font-face {
  font-family: MavenRegular;
  src: url(../public/fonts/MavenPro-Regular.ttf);
}