.element-home-our-story {
  /* background-color: #ffffff; */
  width: 100%;
  height: 100vh;
  /* background-color: red; */
  /* width:200px; */
  cursor: pointer;
}


.continue {
  height: auto;
  left: 980px;
  position: relative;
  top: 660px;
  width: auto;
  cursor: pointer;
}

.mobile-home {
  display: none;
}

.element-home-our-story .element-i-home-our-story {
  width: 100%;
  /* transition: opacity 2s ease-out; */
}

.element-home-our-story .hover1 {
  height: auto;
  left: 535px;
  position: absolute;
  top: 190px;
  width: auto;
}

.element-home-our-story .hover2 {
  height: auto;
  left: 490px;
  position: absolute;
  top: 229px;
  width: auto;
}

.element-home-our-story .hover3 {
  height: 500px;
  left: 112px;
  position: absolute;
  top: 70px;
  width: 800px;
}

.element-home-our-story .hover4 {
  height: auto;
  left: 105px;
  position: absolute;
  top: 150px;
  width: 650px;
}
.element-home-our-story .hover5 {
  height: auto;
  left: 65px;
  position: absolute;
  top: 70px;
  width: auto;
}
.element-home-our-story .hover6 {
  height: auto;
  left: 375px;
  position: absolute;
  top: 279px;
  width: auto;
}

.element-home-our-story .hover7 {
  height: auto;
  left: 343px;
  position: absolute;
  top: 252px;
  width: auto;
}
.element-home-our-story .overlap-group {
  height: 1080px;
  width: 100%;
  position: relative;
}

.element-home-our-story .overlap {
  left: 4%;
  position: absolute;
  margin: auto;
  top: 12%;
  width: 100%;
  /* width: 1191px; */
}

.element-home-our-story .div {
  height: 639px;
  left: 0;
  position: absolute;
  top: 0;
  /* width: 11px; */
}

.element-home-our-story .creative-innovation {
  height: 165px;
  left: 181px;
  position: absolute;
  top: 4px;
  width: 172px;
}

.element-home-our-story .HR {
  height: 151px;
  left: 26px;
  position: absolute;
  top: 65px;
  width: 106px;
}


.element-home-our-story .sound {
  height: 159px;
  left: 620px;
  position: absolute;
  top: 108px;
  width: 112px;
}

.element-home-our-story .smell {
  height: 173px;
  left: 983px;
  position: absolute;
  top: 458px;
  width: 208px;
}

.element-home-our-story .element-goal {
  height: 152px;
  left: 464px;
  position: absolute;
  top: 34px;
  width: 220px;
}

.element-home-our-story .two-directions {
  height: 174px;
  left: 987px;
  position: absolute;
  top: 292px;
  width: 203px;
}

.element-home-our-story .data {
  height: 162px;
  left: 0;
  position: absolute;
  top: 0;
  width: 164px;
}

.element-home-our-story .idea {
  height: 230px;
  left: 803px;
  position: absolute;
  top: 198px;
  width: 208px;
}

.element-home-our-story .technology {
  height: 211px;
  left: 395px;
  position: absolute;
  top: 198px;
  width: 211px;
}

.element-home-our-story .vector {
  height: 279px;
  left: 157px;
  position: absolute;
  top: 161px;
  width: 240px;
}

.element-home-our-story .vector-2 {
  height: 33px;
  left: 247px;
  position: absolute;
  top: 246px;
  width: 33px;
}

.element-home-our-story .th-advantage-people {
  height: 223px;
  left: 202px;
  position: absolute;
  top: 416px;
  width: 696px;
}

.element-home-our-story .sight {
  height: 145px;
  left: 617px;
  position: absolute;
  top: 285px;
  width: 196px;
}

.element-home-our-story .filler {
  height: 40px;
  left: 124px;
  position: absolute;
  top: 397px;
  width: 40px;
}

.element-home-our-story .filler-2 {
  height: 33px;
  left: 320px;
  position: absolute;
  top: 15px;
  width: 188px;
}

.element-home-our-story .vector-3 {
  height: 38px;
  left: 977px;
  position: absolute;
  top: 179px;
  width: 41px;
}

.element-home-our-story .overlap-2 {
  height: 262px;
  left: 703px;
  position: absolute;
  top: 5px;
  width: 480px;
}

.element-home-our-story .individual {
  height: 159px;
  left: 176px;
  position: absolute;
  top: 2px;
  width: 169px;
}

.element-home-our-story .touch {
  height: 262px;
  left: 338px;
  position: absolute;
  top: 0;
  width: 141px;
}

.element-home-our-story .filler-3 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 188px;
}

.element-home-our-story .brand-management {
  height: 169px;
  left: 3px;
  position: absolute;
  top: 468px;
  width: 169px;
}

.element-home-our-story .finance {
  height: 108px;
  left: 8px;
  position: absolute;
  top: 346px;
  width: 98px;
}

.element-home-our-story .tatste {
  height: 146px;
  left: 1px;
  position: absolute;
  top: 185px;
  width: 137px;
}

.element-home-our-story .vector-4 {
  height: 33px;
  left: 369px;
  position: absolute;
  top: 114px;
  width: 33px;
}

.element-home-our-story .filler-4 {
  height: 188px;
  left: 933px;
  position: absolute;
  top: 438px;
  width: 33px;
}

.element-home-our-story .filler-5 {
  height: 47px;
  left: 746px;
  position: absolute;
  top: 232px;
  width: 47px;
}

.element-home-our-story .frame {
  height: 23px;
  left: 1361px;
  position: absolute;
  top: 899px;
  width: 119px;
}


@media (max-width: 1600px) {
  .continue {
    height: auto;
    left: 840px;
    position: absolute;
    top: 585px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story .hover1 {
    height: auto;
    left: 465px;
    position: absolute;
    top: 190px;
    width: auto;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 355px;
    position: absolute;
    top: 203px;
    width: auto;
  }

  .element-home-our-story .hover3 {
    height: 400px;
    left: 95px;
    position: absolute;
    top: 80px;
    width: 700px;
  }

  .element-home-our-story .hover4 {
    height: 350px;
    left: 75px;
    position: absolute;
    top: 120px;
    width: 580px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 55px;
    position: absolute;
    top: 72px;
    width: 900px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 320px;
    position: absolute;
    top: 229px;
    width: auto;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 298px;
    position: absolute;
    top: 202px;
    width: auto;
  }
  .element-home-our-story {
    /* background-color: #ffffff; */
    width: 100%;
    height: 100vh;
    /* background-color: red; */
    /* width:200px; */
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    /* height: 1080px; */
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 1080px;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: 10%;
    position: absolute;
    margin: auto;
    top: 4%;
    width: 100%;
    /* width: 1191px; */
  }

  .element-home-our-story .div {
    height: 639px;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .creative-innovation {
    height: 125px;
    left: 161px;
    position: absolute;
    top: 4px;
    width: 132px;
  }

  .element-home-our-story .HR {
    height: 151px;
    left: 18px;
    position: absolute;
    top: 65px;
    width: 106px;
  }

  .element-home-our-story .sound {
    height: 139px;
    left: 530px;
    position: absolute;
    top: 110px;
    width: 102px;
  }

  .element-home-our-story .smell {
    height: 143px;
    left: 860px;
    position: absolute;
    top: 415px;
    width: 170px;
  }

  .element-home-our-story .element-goal {
    height: 142px;
    left: 399px;
    position: absolute;
    top: 34px;
    width: 200px;
  }

  .element-home-our-story .two-directions {
    height: 144px;
    left: 850px;
    position: absolute;
    top: 280px;
    width: 180px;
  }

  .element-home-our-story .data {
    height: 122px;
    left: 0;
    position: absolute;
    top: 0;
    width: 134px;
  }

  .element-home-our-story .idea {
    height: 200px;
    left: 703px;
    position: absolute;
    top: 198px;
    width: 180px;
  }

  .element-home-our-story .technology {
    height: 170px;
    left: 345px;
    position: absolute;
    top: 185px;
    width: 170px;
  }

  .element-home-our-story .vector {
    height: 240px;
    left: 135px;
    position: absolute;
    top: 128px;
    width: 200px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .th-advantage-people {
    height: 180px;
    left: 170px;
    position: absolute;
    top: 378px;
    width: 630px;
  }

  .element-home-our-story .sight {
    height: 125px;
    left: 527px;
    position: absolute;
    top: 265px;
    width: 176px;
  }

  .element-home-our-story .filler {
    height: 30px;
    left: 99px;
    position: absolute;
    top: 350px;
    width: 30px;
  }

  .element-home-our-story .filler-2 {
    height: 28px;
    left: 280px;
    position: absolute;
    top: 15px;
    width: 158px;
  }

  .element-home-our-story .vector-3 {
    height: 38px;
    left: 847px;
    position: absolute;
    top: 169px;
    width: 41px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 623px;
    position: absolute;
    top: 5px;
    width: 480px;
  }

  .element-home-our-story .individual {
    height: 139px;
    left: 146px;
    position: absolute;
    top: 2px;
    width: 139px;
  }

  .element-home-our-story .touch {
    height: 232px;
    left: 278px;
    position: absolute;
    top: 0;
    width: 121px;
  }

  .element-home-our-story .filler-3 {
    height: 28px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 158px;
  }

  .element-home-our-story .brand-management {
    height: 130px;
    left: 3px;
    position: absolute;
    top: 425px;
    width: 139px;
  }

  .element-home-our-story .finance {
    height: 88px;
    left: 8px;
    position: absolute;
    top: 300px;
    width: 78px;
  }

  .element-home-our-story .tatste {
    height: 126px;
    left: 0px;
    position: absolute;
    top: 150px;
    width: 117px;
  }

  .element-home-our-story .vector-4 {
    height: 33px;
    left: 309px;
    position: absolute;
    top: 85px;
    width: 33px;
  }

  .element-home-our-story .filler-4 {
    height: 158px;
    left: 819px;
    position: absolute;
    top: 398px;
    width: 23px;
  }

  .element-home-our-story .filler-5 {
    height: 37px;
    left: 666px;
    position: absolute;
    top: 232px;
    width: 37px;
  }

 
}
@media (max-width: 1440px) {
  .continue {
    height: auto;
    left: 840px;
    position: absolute;
    top: 585px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story .hover1 {
    height: auto;
    left: 465px;
    position: absolute;
    top: 190px;
    width: auto;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 355px;
    position: absolute;
    top: 203px;
    width: auto;
  }

  .element-home-our-story .hover3 {
    height: 400px;
    left: 95px;
    position: absolute;
    top: 80px;
    width: 700px;
  }

  .element-home-our-story .hover4 {
    height: 350px;
    left: 75px;
    position: absolute;
    top: 120px;
    width: 580px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 55px;
    position: absolute;
    top: 72px;
    width: 900px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 320px;
    position: absolute;
    top: 229px;
    width: auto;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 298px;
    position: absolute;
    top: 202px;
    width: auto;
  }
  .element-home-our-story {
    /* background-color: #ffffff; */
    width: 100%;
    height: 100vh;
    /* background-color: red; */
    /* width:200px; */
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    /* height: 1080px; */
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 1080px;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: 10%;
    position: absolute;
    margin: auto;
    top: 10%;
    width: 100%;
    /* width: 1191px; */
  }

  .element-home-our-story .div {
    height: 639px;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .creative-innovation {
    height: 125px;
    left: 161px;
    position: absolute;
    top: 4px;
    width: 132px;
  }

  .element-home-our-story .HR {
    height: 151px;
    left: 18px;
    position: absolute;
    top: 65px;
    width: 106px;
  }

  .element-home-our-story .sound {
    height: 139px;
    left: 530px;
    position: absolute;
    top: 110px;
    width: 102px;
  }

  .element-home-our-story .smell {
    height: 143px;
    left: 860px;
    position: absolute;
    top: 415px;
    width: 170px;
  }

  .element-home-our-story .element-goal {
    height: 142px;
    left: 399px;
    position: absolute;
    top: 34px;
    width: 200px;
  }

  .element-home-our-story .two-directions {
    height: 144px;
    left: 850px;
    position: absolute;
    top: 280px;
    width: 180px;
  }

  .element-home-our-story .data {
    height: 122px;
    left: 0;
    position: absolute;
    top: 0;
    width: 134px;
  }

  .element-home-our-story .idea {
    height: 200px;
    left: 703px;
    position: absolute;
    top: 198px;
    width: 180px;
  }

  .element-home-our-story .technology {
    height: 170px;
    left: 345px;
    position: absolute;
    top: 185px;
    width: 170px;
  }

  .element-home-our-story .vector {
    height: 240px;
    left: 135px;
    position: absolute;
    top: 128px;
    width: 200px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .th-advantage-people {
    height: 180px;
    left: 170px;
    position: absolute;
    top: 378px;
    width: 630px;
  }

  .element-home-our-story .sight {
    height: 125px;
    left: 527px;
    position: absolute;
    top: 265px;
    width: 176px;
  }

  .element-home-our-story .filler {
    height: 30px;
    left: 99px;
    position: absolute;
    top: 350px;
    width: 30px;
  }

  .element-home-our-story .filler-2 {
    height: 28px;
    left: 280px;
    position: absolute;
    top: 15px;
    width: 158px;
  }

  .element-home-our-story .vector-3 {
    height: 38px;
    left: 847px;
    position: absolute;
    top: 169px;
    width: 41px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 623px;
    position: absolute;
    top: 5px;
    width: 480px;
  }

  .element-home-our-story .individual {
    height: 139px;
    left: 146px;
    position: absolute;
    top: 2px;
    width: 139px;
  }

  .element-home-our-story .touch {
    height: 232px;
    left: 278px;
    position: absolute;
    top: 0;
    width: 121px;
  }

  .element-home-our-story .filler-3 {
    height: 28px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 158px;
  }

  .element-home-our-story .brand-management {
    height: 130px;
    left: 3px;
    position: absolute;
    top: 425px;
    width: 139px;
  }

  .element-home-our-story .finance {
    height: 88px;
    left: 8px;
    position: absolute;
    top: 300px;
    width: 78px;
  }

  .element-home-our-story .tatste {
    height: 126px;
    left: 0px;
    position: absolute;
    top: 150px;
    width: 117px;
  }

  .element-home-our-story .vector-4 {
    height: 33px;
    left: 309px;
    position: absolute;
    top: 85px;
    width: 33px;
  }

  .element-home-our-story .filler-4 {
    height: 158px;
    left: 819px;
    position: absolute;
    top: 398px;
    width: 23px;
  }

  .element-home-our-story .filler-5 {
    height: 37px;
    left: 666px;
    position: absolute;
    top: 232px;
    width: 37px;
  }

 
}

@media (max-width: 1280px) {
  .continue {
    height: auto;
    left: 840px;
    position: relative;
    bottom: 140px;
    width: auto;
    cursor: pointer;
  }

  .element-home-our-story .hover1 {
    height: auto;
    left: 465px;
    position: absolute;
    top: 190px;
    width: auto;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 355px;
    position: absolute;
    top: 203px;
    width: auto;
  }

  .element-home-our-story .hover3 {
    height: 400px;
    left: 95px;
    position: absolute;
    top: 80px;
    width: 700px;
  }

  .element-home-our-story .hover4 {
    height: 350px;
    left: 75px;
    position: absolute;
    top: 120px;
    width: 580px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 55px;
    position: absolute;
    top: 72px;
    width: 900px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 320px;
    position: absolute;
    top: 229px;
    width: auto;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 298px;
    position: absolute;
    top: 202px;
    width: auto;
  }
  .element-home-our-story {
    /* background-color: #ffffff; */
    width: 100%;
    height: 100vh;
    /* background-color: red; */
    /* width:200px; */
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    /* height: 1080px; */
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 1080px;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: 3.5%;
    position: absolute;
    margin: auto;
    top: 6%;
    width: 100%;
    /* width: 1191px; */
  }

  .element-home-our-story .div {
    height: 639px;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .creative-innovation {
    height: 125px;
    left: 161px;
    position: absolute;
    top: 4px;
    width: 132px;
  }


  .element-home-our-story .HR {
    height: 151px;
    left: 18px;
    position: absolute;
    top: 65px;
    width: 106px;
  }
  .element-home-our-story .sound {
    height: 139px;
    left: 530px;
    position: absolute;
    top: 110px;
    width: 102px;
  }

  .element-home-our-story .smell {
    height: 143px;
    left: 860px;
    position: absolute;
    top: 415px;
    width: 170px;
  }

  .element-home-our-story .element-goal {
    height: 142px;
    left: 399px;
    position: absolute;
    top: 34px;
    width: 200px;
  }

  .element-home-our-story .two-directions {
    height: 144px;
    left: 850px;
    position: absolute;
    top: 280px;
    width: 180px;
  }

  .element-home-our-story .data {
    height: 122px;
    left: 0;
    position: absolute;
    top: 0;
    width: 134px;
  }

  .element-home-our-story .idea {
    height: 200px;
    left: 703px;
    position: absolute;
    top: 198px;
    width: 180px;
  }

  .element-home-our-story .technology {
    height: 170px;
    left: 345px;
    position: absolute;
    top: 185px;
    width: 170px;
  }

  .element-home-our-story .vector {
    height: 240px;
    left: 135px;
    position: absolute;
    top: 128px;
    width: 200px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .th-advantage-people {
    height: 180px;
    left: 170px;
    position: absolute;
    top: 378px;
    width: 630px;
  }

  .element-home-our-story .sight {
    height: 125px;
    left: 527px;
    position: absolute;
    top: 265px;
    width: 176px;
  }

  .element-home-our-story .filler {
    height: 30px;
    left: 99px;
    position: absolute;
    top: 350px;
    width: 30px;
  }

  .element-home-our-story .filler-2 {
    height: 28px;
    left: 280px;
    position: absolute;
    top: 15px;
    width: 158px;
  }

  .element-home-our-story .vector-3 {
    height: 38px;
    left: 847px;
    position: absolute;
    top: 169px;
    width: 41px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 623px;
    position: absolute;
    top: 5px;
    width: 480px;
  }

  .element-home-our-story .individual {
    height: 139px;
    left: 146px;
    position: absolute;
    top: 2px;
    width: 139px;
  }

  .element-home-our-story .touch {
    height: 232px;
    left: 278px;
    position: absolute;
    top: 0;
    width: 121px;
  }

  .element-home-our-story .filler-3 {
    height: 28px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 158px;
  }

  .element-home-our-story .brand-management {
    height: 130px;
    left: 3px;
    position: absolute;
    top: 425px;
    width: 139px;
  }

  .element-home-our-story .finance {
    height: 88px;
    left: 8px;
    position: absolute;
    top: 300px;
    width: 78px;
  }

  .element-home-our-story .tatste {
    height: 126px;
    left: 0px;
    position: absolute;
    top: 150px;
    width: 117px;
  }

  .element-home-our-story .vector-4 {
    height: 33px;
    left: 309px;
    position: absolute;
    top: 85px;
    width: 33px;
  }

  .element-home-our-story .filler-4 {
    height: 158px;
    left: 819px;
    position: absolute;
    top: 398px;
    width: 23px;
  }

  .element-home-our-story .filler-5 {
    height: 37px;
    left: 666px;
    position: absolute;
    top: 232px;
    width: 37px;
  }

  .element-home-our-story .frame {
    height: 23px;
    left: 1361px;
    position: absolute;
    top: 899px;
    width: 119px;
  }

  .element-home-our-story .continue-instance {
    width: unset !important;
  }

  .element-home-our-story .element-for-blue {
    height: 41px;
    left: 50px;
    object-fit: cover;
    position: absolute;
    top: 50px;
    width: 38px;
  }

  .element-home-our-story .hamburger-menu-instance {
    left: 1837px !important;
    position: absolute !important;
    top: 55px !important;
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .continue {
    height: auto;
    left: 0px;
    position: absolute;
    bottom: 10px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story .hover1 {
    height: auto;
    left: 340px;
    position: absolute;
    top: 140px;
    width: 250px;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 355px;
    position: absolute;
    top: 203px;
    width: auto;
  }

  .element-home-our-story .hover3 {
    height: auto;
    left: 65px;
    position: absolute;
    top: 60px;
    width: 500px;
  }

  .element-home-our-story .hover4 {
    height: auto;
    left: 75px;
    position: absolute;
    top: 80px;
    width: 400px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 45px;
    position: absolute;
    top: 52px;
    width: 620px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 240px;
    position: absolute;
    top: 180px;
    width: 380px;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 225px;
    position: absolute;
    top: 102px;
    width: 340px;
  }
  .element-home-our-story {
    width: 100%;
    height: 50vh;
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    /* height: 1080px; */
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 1080px;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: 10%;
    position: absolute;
    margin: auto;
    top: 12%;
    width: 100%;
    /* width: 1191px; */
  }

  .element-home-our-story .div {
    height: 639px;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .data {
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .element-home-our-story .tatste {
    height: 86px;
    left: 0px;
    position: absolute;
    top: 100px;
    width: 80px;
  }

  .element-home-our-story .finance {
    height: 68px;
    left: 8px;
    position: absolute;
    top: 200px;
    width: 58px;
  }
  .element-home-our-story .filler {
    height: 20px;
    left: 75px;
    position: absolute;
    top: 245px;
    width: 20px;
  }

  .element-home-our-story .brand-management {
    height: 100px;
    left: 3px;
    position: absolute;
    top: 300px;
    width: 110px;
  }

  .element-home-our-story .creative-innovation {
    height: 80px;
    left: 111px;
    position: absolute;
    top: 4px;
    width: 90px;
  }

  .element-home-our-story .vector {
    height: 170px;
    left: 100px;
    position: absolute;
    top: 99px;
    width: 150px;
  }

  .element-home-our-story .th-advantage-people {
    height: 130px;
    left: 135px;
    position: absolute;
    top: 268px;
    width: 450px;
  }

  .element-home-our-story .filler-2 {
    left: 200px;
    position: absolute;
    top: 15px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .vector-4 {
    height: 23px;
    left: 232px;
    position: absolute;
    top: 65px;
    width: 23px;
  }

  .element-home-our-story .element-goal {
    height: 90px;
    left: 300px;
    position: absolute;
    top: 34px;
    width: 120px;
  }

  .element-home-our-story .technology {
    height: 120px;
    left: 255px;
    position: absolute;
    top: 140px;
    width: 120px;
  }

  .element-home-our-story .sound {
    height: 89px;
    left: 380px;
    position: absolute;
    top: 80px;
    width: 72px;
  }

  .element-home-our-story .sight {
    height: 80px;
    left: 387px;
    position: absolute;
    top: 180px;
    width: 102px;
  }
  .element-home-our-story .filler-3 {
    left: 0;
    position: absolute;
    top: 10px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .HR {
    height: 100px;
    left: 18px;
    position: absolute;
    top: 50px;
    width: 76px;

  }
  .element-home-our-story .filler-5 {
    height: 27px;
    left: 466px;
    position: absolute;
    top: 162px;
    width: 27px;
  }

  .element-home-our-story .individual {
    height: 89px;
    left: 116px;
    position: absolute;
    top: 2px;
    width: 89px;
  }

  .element-home-our-story .vector-3 {
    height: 28px;
    left: 600px;
    position: absolute;
    top: 112px;
    width: 31px;
  }

  .element-home-our-story .idea {
    height: 130px;
    left: 503px;
    position: absolute;
    top: 133px;
    width: 120px;
  }

  .element-home-our-story .filler-4 {
    left: 595px;
    position: absolute;
    top: 278px;
    height: 115px;
    width: 15px;
  }

  .element-home-our-story .touch {
    height: 152px;
    left: 210px;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .element-home-our-story .two-directions {
    height: 114px;
    left: 605px;
    position: absolute;
    top: 180px;
    width: 120px;
  }

  .element-home-our-story .smell {
    height: 100px;
    left: 620px;
    position: absolute;
    top: 300px;
    width: 100px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 437px;
    position: absolute;
    top: 5px;
    width: 480px;
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  .continue {
    height: auto;
    left: 560px;
    position: relative;
    top: 420px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story .hover1 {
    height: auto;
    left: 340px;
    position: absolute;
    top: 140px;
    width: 250px;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 355px;
    position: absolute;
    top: 203px;
    width: auto;
  }

  .element-home-our-story .hover3 {
    height: auto;
    left: 65px;
    position: absolute;
    top: 60px;
    width: 500px;
  }

  .element-home-our-story .hover4 {
    height: auto;
    left: 75px;
    position: absolute;
    top: 80px;
    width: 400px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 45px;
    position: absolute;
    top: 52px;
    width: 620px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 240px;
    position: absolute;
    top: 180px;
    width: 380px;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 225px;
    position: absolute;
    top: 102px;
    width: 340px;
  }
  .element-home-our-story {
    width: 100%;
    height: 100vh;
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    /* height: 1080px; */
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 1080px;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: 10%;
    position: absolute;
    margin: auto;
    top: 7%;
    width: 100%;
    /* width: 1191px; */
  }

  .element-home-our-story .div {
    height: 639px;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .data {
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .element-home-our-story .tatste {
    height: 86px;
    left: 0px;
    position: absolute;
    top: 100px;
    width: 80px;
  }

  .element-home-our-story .finance {
    height: 68px;
    left: 8px;
    position: absolute;
    top: 200px;
    width: 58px;
  }
  .element-home-our-story .filler {
    height: 20px;
    left: 75px;
    position: absolute;
    top: 245px;
    width: 20px;
  }

  .element-home-our-story .brand-management {
    height: 100px;
    left: 3px;
    position: absolute;
    top: 300px;
    width: 110px;
  }

  .element-home-our-story .creative-innovation {
    height: 80px;
    left: 111px;
    position: absolute;
    top: 4px;
    width: 90px;
  }

  .element-home-our-story .vector {
    height: 170px;
    left: 100px;
    position: absolute;
    top: 99px;
    width: 150px;
  }

  .element-home-our-story .th-advantage-people {
    height: 130px;
    left: 135px;
    position: absolute;
    top: 268px;
    width: 450px;
  }

  .element-home-our-story .filler-2 {
    left: 200px;
    position: absolute;
    top: 15px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .vector-4 {
    height: 23px;
    left: 232px;
    position: absolute;
    top: 65px;
    width: 23px;
  }

  .element-home-our-story .element-goal {
    height: 90px;
    left: 300px;
    position: absolute;
    top: 34px;
    width: 120px;
  }

  .element-home-our-story .technology {
    height: 120px;
    left: 255px;
    position: absolute;
    top: 140px;
    width: 120px;
  }

  .element-home-our-story .sound {
    height: 89px;
    left: 380px;
    position: absolute;
    top: 80px;
    width: 72px;
  }

  .element-home-our-story .sight {
    height: 80px;
    left: 387px;
    position: absolute;
    top: 180px;
    width: 102px;
  }
  .element-home-our-story .filler-3 {
    left: 0;
    position: absolute;
    top: 10px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .HR {
    height: 100px;
    left: 18px;
    position: absolute;
    top: 50px;
    width: 76px;

  }
  .element-home-our-story .filler-5 {
    height: 27px;
    left: 466px;
    position: absolute;
    top: 162px;
    width: 27px;
  }

  .element-home-our-story .individual {
    height: 89px;
    left: 116px;
    position: absolute;
    top: 2px;
    width: 89px;
  }

  .element-home-our-story .vector-3 {
    height: 28px;
    left: 600px;
    position: absolute;
    top: 112px;
    width: 31px;
  }

  .element-home-our-story .idea {
    height: 130px;
    left: 503px;
    position: absolute;
    top: 133px;
    width: 120px;
  }

  .element-home-our-story .filler-4 {
    left: 595px;
    position: absolute;
    top: 278px;
    height: 115px;
    width: 15px;
  }

  .element-home-our-story .touch {
    height: 152px;
    left: 210px;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .element-home-our-story .two-directions {
    height: 114px;
    left: 605px;
    position: absolute;
    top: 180px;
    width: 120px;
  }

  .element-home-our-story .smell {
    height: 100px;
    left: 620px;
    position: absolute;
    top: 300px;
    width: 100px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 437px;
    position: absolute;
    top: 5px;
    width: 480px;
  }
}

@media (max-width: 912px) {
  .continue {
    height: auto;
    left: 560px;
    position: relative;
    top:405px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story .hover1 {
    height: auto;
    left: 340px;
    position: absolute;
    top: 140px;
    width: 250px;
  }

  .element-home-our-story .hover2 {
    height: auto;
    left: 205px;
    position: absolute;
    top: 123px;
    width: 400px;
  }

  .element-home-our-story .hover3 {
    height: auto;
    left: 65px;
    position: absolute;
    top: 60px;
    width: 500px;
  }

  .element-home-our-story .hover4 {
    height: auto;
    left: 75px;
    position: absolute;
    top: 80px;
    width: 400px;
  }
  .element-home-our-story .hover5 {
    height: auto;
    left: 45px;
    position: absolute;
    top: 52px;
    width: 620px;
  }
  .element-home-our-story .hover6 {
    height: auto;
    left: 240px;
    position: absolute;
    top: 180px;
    width: 380px;
  }

  .element-home-our-story .hover7 {
    height: auto;
    left: 225px;
    position: absolute;
    top: 102px;
    width: 340px;
  }
  .element-home-our-story {
    width: 100%;
    height: 50vh;
  }

  .mobile-home {
    display: none;
  }
  .element-home-our-story .element-i-home-our-story {
    height: 50vh;
    width: 100%;
  }

  .element-home-our-story .overlap-group {
    height: 50vh;
    width: 100%;
    position: relative;
  }

  .element-home-our-story .overlap {
    left: -3%;
    position: absolute;
    margin: auto;
    top: 15%;
    width: 100%;
  }

  .element-home-our-story .div {
    height: 50vh;
    left: 0;
    position: absolute;
    top: 0;
    /* width: 11px; */
  }

  .element-home-our-story .data {
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .element-home-our-story .tatste {
    height: 86px;
    left: 0px;
    position: absolute;
    top: 100px;
    width: 80px;
  }

  .element-home-our-story .finance {
    height: 68px;
    left: 8px;
    position: absolute;
    top: 200px;
    width: 58px;
  }
  .element-home-our-story .filler {
    height: 20px;
    left: 75px;
    position: absolute;
    top: 245px;
    width: 20px;
  }

  .element-home-our-story .brand-management {
    height: 100px;
    left: 3px;
    position: absolute;
    top: 300px;
    width: 110px;
  }

  .element-home-our-story .creative-innovation {
    height: 80px;
    left: 111px;
    position: absolute;
    top: 4px;
    width: 90px;
  }

  .element-home-our-story .vector {
    height: 170px;
    left: 100px;
    position: absolute;
    top: 99px;
    width: 150px;
  }

  .element-home-our-story .th-advantage-people {
    height: 130px;
    left: 135px;
    position: absolute;
    top: 268px;
    width: 450px;
  }

  .element-home-our-story .filler-2 {
    left: 200px;
    position: absolute;
    top: 15px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .vector-4 {
    height: 23px;
    left: 232px;
    position: absolute;
    top: 65px;
    width: 23px;
  }

  .element-home-our-story .element-goal {
    height: 90px;
    left: 300px;
    position: absolute;
    top: 34px;
    width: 120px;
  }

  .element-home-our-story .technology {
    height: 120px;
    left: 255px;
    position: absolute;
    top: 140px;
    width: 120px;
  }

  .element-home-our-story .sound {
    height: 89px;
    left: 380px;
    position: absolute;
    top: 80px;
    width: 72px;
  }

  .element-home-our-story .sight {
    height: 80px;
    left: 387px;
    position: absolute;
    top: 180px;
    width: 102px;
  }
  .element-home-our-story .filler-3 {
    left: 0;
    position: absolute;
    top: 10px;
    height: 18px;
    width: 118px;
  }

  .element-home-our-story .HR {
    height: 100px;
    left: 18px;
    position: absolute;
    top: 50px;
    width: 76px;

  }
  .element-home-our-story .filler-5 {
    height: 27px;
    left: 466px;
    position: absolute;
    top: 162px;
    width: 27px;
  }

  .element-home-our-story .individual {
    height: 89px;
    left: 116px;
    position: absolute;
    top: 2px;
    width: 89px;
  }

  .element-home-our-story .vector-3 {
    height: 28px;
    left: 600px;
    position: absolute;
    top: 112px;
    width: 31px;
  }

  .element-home-our-story .idea {
    height: 130px;
    left: 503px;
    position: absolute;
    top: 133px;
    width: 120px;
  }

  .element-home-our-story .filler-4 {
    left: 595px;
    position: absolute;
    top: 278px;
    height: 115px;
    width: 15px;
  }

  .element-home-our-story .touch {
    height: 152px;
    left: 210px;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .element-home-our-story .two-directions {
    height: 114px;
    left: 605px;
    position: absolute;
    top: 180px;
    width: 120px;
  }

  .element-home-our-story .smell {
    height: 100px;
    left: 620px;
    position: absolute;
    top: 300px;
    width: 100px;
  }

  .element-home-our-story .vector-2 {
    height: 33px;
    left: 247px;
    position: absolute;
    top: 246px;
    width: 33px;
  }

  .element-home-our-story .overlap-2 {
    height: 262px;
    /* left: 703px; */
    left: 437px;
    position: absolute;
    top: 5px;
    width: 480px;
  }
}
@media (max-width: 640px) {
  .continue {
    height: auto;
    left: 250px;
    position: relative;
    top: 720px;
    width: auto;
    cursor: pointer;
  }
  .element-home-our-story {
    display: none;
  }
  .mobile-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .mobile-home .hover1m {
    height: auto;
    left: 130px;
    position: absolute;
    top: 412px;
    width: 220px;
  }
  .mobile-home .hover2m {
    height: auto;
    left: 95px;
    position: absolute;
    top: 290px;
    width: 200px;
  }
  .mobile-home .hover3m {
    height: auto;
    left: 120px;
    position: absolute;
    top: 310px;
    width: 240px;
  }
  .mobile-home .hover4m {
    height: auto;
    left: 87px;
    position: absolute;
    top: 306px;
    width: 320px;
  }
  .mobile-home .hover5m {
    height: auto;
    left: 120px;
    position: absolute;
    top: 317px;
    width: 280px;
  }
  .mobile-home .hover6m {
    height: auto;
    left: 215px;
    position: absolute;
    top: 297px;
    width: 180px;
  }
  .mobile-home .hover7m {
    height: auto;
    left: 115px;
    position: absolute;
    top: 297px;
    width: 230px;
  }
  .mobile-home .overlap-group-wrapper {
    height: 100vh;
    overflow: hidden;
    width: 430px;
  }

  .mobile-home .overlap-group {
    background-color: #030c2d;
    height: 70vh;
    left: -25px;
    /* left:-18%; */
    position: relative;
    top: -250px;
    width: 492px;
  }

  .mobile-home .overlap {
    height: 447px;
    left: 75px;
    position: absolute;
    top: 265px;
    width: 342px;
  }

  .mobile-home .creatives {
    height: 65px;
    left: 269px;
    position: absolute;
    top: 191px;
    width: 68px;
  }

  .mobile-home .individual {
    height: 64px;
    left: 261px;
    position: absolute;
    top: 293px;
    width: 68px;
  }

  .mobile-home .hr {
    height: 70px;
    left: 146px;
    position: absolute;
    top: 88px;
    width: 49px;
  }

  .mobile-home .tatste {
    height: 37px;
    left: 55px;
    position: absolute;
    top: 173px;
    width: 35px;
  }

  .mobile-home .smell {
    height: 65px;
    left: 0;
    position: absolute;
    top: 279px;
    width: 78px;
  }

  .mobile-home .element-goal {
    height: 71px;
    left: 196px;
    position: absolute;
    top: 263px;
    width: 103px;
  }

  .mobile-home .two-directions {
    height: 108px;
    left: 216px;
    position: absolute;
    top: 89px;
    width: 126px;
  }

  .mobile-home .idea {
    height: 91px;
    left: 89px;
    position: absolute;
    top: 0;
    width: 82px;
  }

  .mobile-home .technology {
    height: 80px;
    left: 53px;
    position: absolute;
    top: 85px;
    width: 81px;
  }

  .mobile-home .iv {
    height: 175px;
    left: 74px;
    position: absolute;
    top: 155px;
    width: 132px;
  }

  .mobile-home .touch {
    height: 111px;
    left: 0;
    position: absolute;
    top: 159px;
    width: 60px;
  }

  .mobile-home .people {
    height: 103px;
    left: 7px;
    position: absolute;
    top: 343px;
    width: 323px;
  }

  .mobile-home .sight {
    height: 54px;
    left: 192px;
    position: absolute;
    top: 204px;
    width: 73px;
  }

  .mobile-home .filter {
    height: 15px;
    left: 193px;
    position: absolute;
    top: 90px;
    width: 87px;
  }

  .mobile-home .group {
    height: 15px;
    left: 76px;
    position: absolute;
    top: 336px;
    width: 87px;
  }

  .mobile-home .img {
    height: 15px;
    left: 93px;
    position: absolute;
    top: 170px;
    width: 87px;
  }

  .mobile-home .brand-management {
    height: 78px;
    left: 254px;
    position: absolute;
    top: 267px;
    width: 78px;
  }

  .mobile-home .finance {
    height: 50px;
    left: 75px;
    position: absolute;
    top: 361px;
    width: 45px;
  }

  .mobile-home .sound {
    height: 82px;
    left: 342px;
    position: absolute;
    top: 270px;
    width: 58px;
  }

  .mobile-home .data {
    height: 75px;
    left: 79px;
    position: absolute;
    top: 273px;
    width: 76px;
  }
}

@media (max-width: 480px) {
  .continue {
    height: auto;
    left: 70%;
    position: absolute;
    top: 750px;
    width: auto;
  }
  .element-home-our-story {
    display: none;
  }
  .mobile-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .mobile-home .overlap-group-wrapper {
    height: 100vh;
    overflow: hidden;
    /* width: 430px; */
    width: 100%;
  }

  .mobile-home .overlap-group {
    height: 100vh;
    /* left: -58px; */
    left: -62px;

    position: relative;
    top: -200px;
    width: 100%;
  }

  .mobile-home .overlap {
    height: 447px;
    left: 75px;
    position: absolute;
    top: 265px;
    width: 100%;
    /* width: 342px; */
  }

  .mobile-home .hover1m {
    height: auto;
    left: 130px;
    position: absolute;
    top: 412px;
    width: 220px;
  }
  .mobile-home .hover2m {
    height: auto;
    left: 95px;
    position: absolute;
    top: 290px;
    width: 200px;
  }
  .mobile-home .hover3m {
    height: auto;
    left: 120px;
    position: absolute;
    top: 310px;
    width: 240px;
  }
  .mobile-home .hover4m {
    height: auto;
    left: 87px;
    position: absolute;
    top: 306px;
    width: 320px;
  }
  .mobile-home .hover5m {
    height: auto;
    left: 130px;
    position: absolute;
    top: 327px;
    width: 270px;
  }
  .mobile-home .hover6m {
    height: auto;
    left: 215px;
    position: absolute;
    top: 297px;
    width: 180px;
  }
  .mobile-home .hover7m {
    height: auto;
    left: 115px;
    position: absolute;
    top: 297px;
    width: 230px;
  }
  .mobile-home .creatives {
    height: 65px;
    left: 269px;
    position: absolute;
    top: 191px;
    width: 68px;
  }

  .mobile-home .individual {
    height: 64px;
    left: 261px;
    position: absolute;
    top: 293px;
    width: 68px;
  }

  .mobile-home .hr {
    height: 70px;
    left: 146px;
    position: absolute;
    top: 88px;
    width: 49px;
  }

  .mobile-home .tatste {
    height: 37px;
    left: 55px;
    position: absolute;
    top: 173px;
    width: 35px;
  }

  .mobile-home .smell {
    height: 65px;
    left: 0;
    position: absolute;
    top: 279px;
    width: 78px;
  }

  .mobile-home .element-goal {
    height: 71px;
    left: 196px;
    position: absolute;
    top: 263px;
    width: 103px;
  }

  .mobile-home .two-directions {
    height: 108px;
    left: 216px;
    position: absolute;
    top: 89px;
    width: 126px;
  }

  .mobile-home .idea {
    height: 91px;
    left: 89px;
    position: absolute;
    top: 0;
    width: 82px;
  }

  .mobile-home .technology {
    height: 80px;
    left: 53px;
    position: absolute;
    top: 85px;
    width: 81px;
  }

  .mobile-home .iv {
    height: 175px;
    left: 74px;
    position: absolute;
    top: 155px;
    width: 132px;
  }

  .mobile-home .touch {
    height: 111px;
    left: 0;
    position: absolute;
    top: 159px;
    width: 60px;
  }

  .mobile-home .people {
    height: 103px;
    left: 7px;
    position: absolute;
    top: 343px;
    width: 323px;
  }

  .mobile-home .sight {
    height: 54px;
    left: 192px;
    position: absolute;
    top: 204px;
    width: 73px;
  }

  .mobile-home .filter {
    height: 15px;
    left: 193px;
    position: absolute;
    top: 90px;
    width: 87px;
  }

  .mobile-home .group {
    height: 15px;
    left: 76px;
    position: absolute;
    top: 336px;
    width: 87px;
  }

  .mobile-home .img {
    height: 15px;
    left: 93px;
    position: absolute;
    top: 170px;
    width: 87px;
  }

  .mobile-home .brand-management {
    height: 78px;
    left: 254px;
    position: absolute;
    top: 267px;
    width: 78px;
  }

  .mobile-home .finance {
    height: 50px;
    left: 75px;
    position: absolute;
    top: 361px;
    width: 45px;
  }

  .mobile-home .sound {
    height: 82px;
    left: 342px;
    position: absolute;
    top: 270px;
    width: 58px;
  }

  .mobile-home .data {
    height: 75px;
    left: 79px;
    position: absolute;
    top: 273px;
    width: 76px;
  }
}


@media (max-width: 360px) and (min-height: 740px) {
  .continue {
    height: auto;
    left: 230px;
    position: absolute;
    bottom: 20px;
    width: auto;
  }
  
  .mobile-home .hover1m {
    height: auto;
    left: 110px;
    position: absolute;
    top: 408px;
    width: 220px;
  }
  .mobile-home .hover2m {
    height: auto;
    left: 105px;
    position: absolute;
    top: 315px;
    width: 160px;
  }
  .mobile-home .hover3m {
    height: auto;
    left: 110px;
    position: absolute;
    top: 327px;
    width: 220px;
  }
  .mobile-home .hover4m {
    height: auto;
    left: 90px;
    position: absolute;
    top: 326px;
    width: 280px;
  }
  .mobile-home .hover5m {
    height: auto;
    left: 120px;
    position: absolute;
    top: 347px;
    width: 250px;
  }
  .mobile-home .hover6m {
    height: auto;
    left: 215px;
    position: absolute;
    top: 345px;
    width: 140px;
  }
  .mobile-home .hover7m {
    height: auto;
    left: 100px;
    position: absolute;
    top: 308px;
    width: 230px;
  }
  .mobile-home .overlap-group {
    height: 100vh;
    left: -62px;
    /* left:-18%; */
    position: relative;
    top: -142px;
    width: 492px;
  }

  .mobile-home .overlap-group-wrapper {
    height: 100vh;
    overflow: hidden;
    width: 430px;
  }
  .mobile-home .creatives {
    height: 55px;
    left: 240px;
    position: absolute;
    top: 202px;
    width: 58px;
  }

  .mobile-home .individual {
    height: 64px;
    left: 225px;
    position: absolute;
    top: 293px;
    width: 68px;
  }

  .mobile-home .hr {
    height: 70px;
    left: 129px;
    position: absolute;
    top: 95px;
    width: 49px;
  }

  .mobile-home .tatste {
    height: 37px;
    left: 50px;
    position: absolute;
    top: 173px;
    width: 35px;
  }

  .mobile-home .smell {
    height: 75px;
    left: 0;
    position: absolute;
    top: 269px;
    width: 78px;
  }

  .mobile-home .element-goal {
    height: 61px;
    left: 176px;
    position: absolute;
    top: 263px;
    width: 83px;
  }

  .mobile-home .two-directions {
    height: 88px;
    left: 190px;
    position: absolute;
    top: 100px;
    width: 106px;
  }

  .mobile-home .idea {
    height: 81px;
    left: 79px;
    position: absolute;
    top: 20px;
    width: 72px;
  }

  .mobile-home .technology {
    height: 70px;
    left: 53px;
    position: absolute;
    top: 98px;
    width: 71px;
  }

  .mobile-home .iv {
    height: 155px;
    left: 74px;
    position: absolute;
    top: 165px;
    width: 112px;
  }

  .mobile-home .touch {
    height: 100px;
    left: 0;
    position: absolute;
    top: 169px;
    width: 50px;
  }

  .mobile-home .people {
    height: 83px;
    left: 0px;
    position: absolute;
    top: 353px;
    width: 293px;
  }

  .mobile-home .sight {
    height: 50px;
    left: 175px;
    position: absolute;
    top: 210px;
    width: 65px;
  }

  .mobile-home .filter {
    height: 15px;
    left: 172px;
    position: absolute;
    top: 97px;
    width: 67px;
  }

  .mobile-home .group {
    height: 15px;
    left: 76px;
    position: absolute;
    top: 331px;
    width: 77px;
  }

  .mobile-home .img {
    height: 15px;
    left: 85px;
    position: absolute;
    top: 175px;
    width: 77px;
  }

  .mobile-home .brand-management {
    height: 65px;
    left: 240px;
    position: absolute;
    top: 293px;
    width: 58px;
  }

  .mobile-home .finance {
    height: 50px;
    left: 75px;
    position: absolute;
    top: 371px;
    width: 45px;
  }

  .mobile-home .sound {
    height: 72px;
    left: 312px;
    position: absolute;
    top: 293px;
    width: 58px;
  }

  .mobile-home .data {
    height: 55px;
    left: 79px;
    position: absolute;
    top: 298px;
    width: 66px;
  }
}

@media (max-width: 320px) and (min-height: 658px) {

    .continue {
    height: auto;
    left: 230px;
    position: absolute;
    top: 720px;
    width: auto;
  }
  .mobile-home .hover1m {
    height: auto;
    left: 115px;
    position: absolute;
    top: 408px;
    width: 220px;
  }
  .mobile-home .hover2m {
    height: auto;
    left: 105px;
    position: absolute;
    top: 315px;
    width: 160px;
  }
  .mobile-home .hover3m {
    height: auto;
    left: 110px;
    position: absolute;
    top: 327px;
    width: 220px;
  }
  .mobile-home .hover4m {
    height: auto;
    left: 90px;
    position: absolute;
    top: 326px;
    width: 280px;
  }
  .mobile-home .hover5m {
    height: auto;
    left: 120px;
    position: absolute;
    top: 347px;
    width: 250px;
  }
  .mobile-home .hover6m {
    height: auto;
    left: 215px;
    position: absolute;
    top: 345px;
    width: 140px;
  }
  .mobile-home .hover7m {
    height: auto;
    left: 97px;
    position: absolute;
    top: 308px;
    width: 230px;
  }
  .mobile-home .overlap-group {
    height: 100vh;
    left: -78px;
    position: relative;
    top: -170px;
    width: 492px;
  }

  .mobile-home .overlap-group-wrapper {
    height: 100vh;
    overflow: hidden;
    width: 430px;
  }

  .mobile-home .creatives {
    height: 55px;
    left: 240px;
    position: absolute;
    top: 202px;
    width: 58px;
  }

  .mobile-home .individual {
    height: 64px;
    left: 225px;
    position: absolute;
    top: 293px;
    width: 68px;
  }

  .mobile-home .hr {
    height: 70px;
    left: 129px;
    position: absolute;
    top: 95px;
    width: 49px;
  }

  .mobile-home .tatste {
    height: 37px;
    left: 50px;
    position: absolute;
    top: 173px;
    width: 35px;
  }

  .mobile-home .smell {
    height: 75px;
    left: 0;
    position: absolute;
    top: 269px;
    width: 78px;
  }

  .mobile-home .element-goal {
    height: 61px;
    left: 176px;
    position: absolute;
    top: 263px;
    width: 83px;
  }

  .mobile-home .two-directions {
    height: 88px;
    left: 190px;
    position: absolute;
    top: 100px;
    width: 106px;
  }

  .mobile-home .idea {
    height: 81px;
    left: 79px;
    position: absolute;
    top: 20px;
    width: 72px;
  }

  .mobile-home .technology {
    height: 70px;
    left: 53px;
    position: absolute;
    top: 98px;
    width: 71px;
  }

  .mobile-home .iv {
    height: 155px;
    left: 74px;
    position: absolute;
    top: 165px;
    width: 112px;
  }

  .mobile-home .touch {
    height: 100px;
    left: 0;
    position: absolute;
    top: 169px;
    width: 50px;
  }

  .mobile-home .people {
    height: 83px;
    left: 0px;
    position: absolute;
    top: 353px;
    width: 293px;
  }

  .mobile-home .sight {
    height: 50px;
    left: 175px;
    position: absolute;
    top: 210px;
    width: 65px;
  }

  .mobile-home .filter {
    height: 15px;
    left: 172px;
    position: absolute;
    top: 97px;
    width: 67px;
  }

  .mobile-home .group {
    height: 15px;
    left: 76px;
    position: absolute;
    top: 331px;
    width: 77px;
  }

  .mobile-home .img {
    height: 15px;
    left: 85px;
    position: absolute;
    top: 175px;
    width: 77px;
  }

  .mobile-home .brand-management {
    height: 65px;
    left: 240px;
    position: absolute;
    top: 293px;
    width: 58px;
  }

  .mobile-home .finance {
    height: 50px;
    left: 75px;
    position: absolute;
    top: 371px;
    width: 45px;
  }

  .mobile-home .sound {
    height: 72px;
    left: 312px;
    position: absolute;
    top: 293px;
    width: 58px;
  }

  .mobile-home .data {
    height: 55px;
    left: 79px;
    position: absolute;
    top: 298px;
    width: 66px;
  }
}



